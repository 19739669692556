import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import * as moment from 'moment-timezone';

import {
  getEstimatedDuration,
  getMaintenanceStatus,
  formatText,
  getDateRange } from '../utils/index.js';

import './styles.scss';
import Cone from '../TopLevelStatus/assets/cone'
import NotOk from '../TopLevelStatus/assets/notok-empty'
import Wrench from '../TopLevelStatus/assets/wrench';

const GetSpan = ({ children }) => (<span>{children}</span>);

const isJSON = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

const getText = (text) => {
  if (isJSON(text)) {
    text = JSON.parse(text);
    if (!!text.en) return text.en;
    const [first] = Object.keys(text)
    return first;
  }
  return text;
}

class Incidents extends React.Component {
  getBackLink = () => {
    const { isIncidentPage } = this.props;
    const backlink = isIncidentPage ? `/history` : '/';

    return backlink;
  }

  goToIncidentPage(incidentId) {
    if (this.props.isIncidentPage) {
      return;
    }

    const { pathname } = this.props.location;

    if (pathname.includes('/history')) {
      this.props.history.push(`${pathname}/${incidentId}`)
    }
  }

  capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  render() {
    let { incidents, statuspage } = this.props;
    const { isIncidentPage } = this.props;
    const Tag = isIncidentPage ? GetSpan : Link;

    return(
      <div className="incidents-container">
        {!isIncidentPage && <span className="incidents-title">History</span>}
        {isIncidentPage === true && <span className="incidents-title">Updates</span>}
        <Link
          className="text-cta nav-link-black float-right"
          to={{
            pathname: this.getBackLink(),
            response: this.props.response,
            statuspage
          }}>
          <span className="arrow">←</span> Back
        </Link>
        <div className="mb-1" />

        {incidents.length <= 0 && <div className="whitebox full-width">No incidents reported.</div>}
        {incidents.map((incident, index) => (
          <Tag to={{ pathname: `/history/${incident.uuid}`, incident, statuspage }}
            className={`no-pointer-cursor incident-link ${!isIncidentPage ? 'history-page' : ''}`}
            key={'incident-' + index}
          >
            <div className="whitebox whitebox-incident">
              <div className="date">{getDateRange(incident)}</div>
              <div className="incident">
                <div className="title">
                  {!!incident.type && incident.type === 'incident' && <Cone className="incident-type-img" />}
                  {!!incident.type && incident.type === 'outage' && <NotOk style={{width:'15px'}} className="incident-type-img" />}
                  {!!incident.type && incident.type === 'maintenance' && <Wrench style={{width:'13px'}} className="incident-type-img" />}
                  {getText(incident.title)}
                </div>
                {(incident.type === 'maintenance') && <div className="estimated-duration">{getEstimatedDuration(incident)}</div>}
                {incident.updates.map((update, index) => (
                  <div className="update" key={'update-' + index}>
                    {!!update && !!update.text &&
                      <>
                      <div className="update-status">{incident.type === 'maintenance' ? getMaintenanceStatus(incident.date, incident.endDate, index) : update.type}</div>
                      <div className="text" dangerouslySetInnerHTML={{ __html: formatText(getText(update.text)) }} />
                      </>
                    }
                    <div className="update-date">{moment(update.UTCTime).format('MMM DD, h:mm A')}</div>
                  </div>
                ))}
                
              </div>
              {!!incident.affectedComponents && !!incident.affectedComponents.length > 0 && <div className="affected-components">
                Affected services: {incident.affectedComponents.map((c, index) => (<span key={'affected-component-' + index} className="affected-component">{c.name}</span>))}
              </div>}
            </div>
          </Tag>
        ))}
      </div>
    );
  }
}

export default withRouter(Incidents);
