import React, { Component } from 'react';
import Uptime from '../Uptime';
import * as moment from 'moment-timezone';

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
const getLabelDate = (date) => {
  const day = date.getDate();
  const month = months[date.getMonth()];
  return `${month} ${day}`;
}

const compUptime = (rows) => {
  const pings = rows.filter(row => row.uptime !== null && row.elapsedTime !== null);
  const uptime = (pings.reduce((acc, day) => acc + day.uptime, 0) / pings.length).toFixed(3);
  return uptime;
}

const compElapsedTime = (rows) => {
  const pings = rows.filter(row => row.uptime !== null && row.elapsedTime !== null);
  const elapsedTime = (pings.reduce((acc, day) => acc + day.elapsedTime, 0) / pings.length).toFixed(0);
  return elapsedTime;
}

class WhiteBoxStats extends Component {
  constructor(props) {
    super(props);

    let color = props.color;

    if (!color.length) {
      color = '#5fead7';
    } else if (color[0] !== '#') {
      color = '#' + color;
    }

    this.state = {
      color
    };
  }

  addHourlyPingsToDailyRows(todayOrYesterday, alias) {
    const services = this.flattenServices();
    const service = services.find(s => s.alias === alias);
    const pings = service.pings;
    const day = todayOrYesterday === 'today' ? moment().toDate() : moment().subtract(1, 'days').toDate();
    const todayPings = pings.filter(ping => moment(ping.date).isSame(day, 'day'));
    const uptime = compUptime(todayPings);
    const elapsedTime = compElapsedTime(todayPings);
    
    const snapshot = {
      date: day,
      hour: day,
      day: getLabelDate(day),
      uptime: Number(uptime),
      elapsedTime: Number(elapsedTime),
      skeleton: false,
      value: day
    }
    return snapshot;
  }

  flattenServices() {
    const { services } = this.props;

    const allServices = [];
    services.forEach((service) => {
      if (!service.isGroup) {
        allServices.push(service);
      } else {
        service.services.forEach((s) => {
          allServices.push(s);
        });
      }
    });

    return allServices;
  }
  
  getGraphs(labels, nbRows) {
    const services = this.flattenServices();
    const graphs = [];

    const servicesWithGraphs = services.filter(service => service.showResponseTime || service.showUptime);

    if (labels === 'days' && !servicesWithGraphs.every(service => !!service.snapshots)) {
      return [];
    }

    services.forEach((service) => {
      let data;
      if (labels === 'hours') {
        data = service.pings;
      } else if (labels === 'days') {
        if (!!service.snapshots) {
          data = service.snapshots;
        } else {
          return;
        }
      }
 
      let meanUptime = null;
      let meanElapsedTime = null;
      let filledDays = [];
      if (labels === 'hours') {
        filledDays = data;
      } else if (labels === 'days') {
        filledDays = data.slice(0, nbRows - 1);
        filledDays.forEach((row) => {
          row.skeleton = false;
          row.day = getLabelDate(new Date(row.date));
          row.value = new Date(row.date);
          row.hour = new Date(row.date);
          row.date = new Date(row.date);
        });
        filledDays.reverse();
        const yesterday = moment().subtract(1, 'days');
        const hasYesterday = filledDays.find(day => moment(day.date).isSame(yesterday, 'day'));
        if (!hasYesterday) {
          filledDays.push(this.addHourlyPingsToDailyRows('yesterday', service.alias));
          if (filledDays.length >= 7) {
            filledDays.shift();
          }
        }
        const today = moment().toDate();
        const hasToday = filledDays.find(day => moment(day.date).isSame(today, 'day'));
        if (!hasToday) {
          filledDays.push(this.addHourlyPingsToDailyRows('today', service.alias));
          if (filledDays.length >= 7) {
            filledDays.shift();
          }
        }
      }

      meanUptime = compUptime(filledDays);
      meanElapsedTime = compElapsedTime(filledDays);

      const payload = {
        name: service.alias || service.domain,
        alias: service.alias,
        data: filledDays,
        hasUptime: service.showUptime,
        hasResponseTime: service.showResponseTime,
        uptime: meanUptime,
        responseTime: meanElapsedTime
      };

      graphs.push(payload);
    });

    return graphs;
  }

  getStats(selectedTimeline) {
    const labels = selectedTimeline > 1 ? 'days' : 'hours';
    const nbRows = selectedTimeline === 1 ? 289 : selectedTimeline;
    const graphsList = this.getGraphs(labels, nbRows);
    
    const graphs = graphsList.filter(graph => graph.hasUptime || graph.hasResponseTime);
    return graphs.map((graph, index) =>
      <div className="whitebox" key={index}>
        {graph.hasUptime && (<div className="stat-wrapper">
          <div className="header seate">
            <div className="left key">{graph.name} Uptime</div>
            <div className="right value">{graph.uptime}%</div>
          </div>
          <Uptime data={graph.data} dataKey="uptime" color={this.state.color} labels={labels} />
          {graph.hasResponseTime && <div className="separator"/>}
        </div>)
        }

        {graph.hasResponseTime && (<div className="stat-wrapper">
          <div className="header separate">
            <div className="left key">{graph.name} Response time</div>
            <div className="right value">{graph.responseTime}ms</div>
          </div>
          <Uptime data={graph.data} dataKey="elapsedTime" color={this.state.color} labels={labels} />
        </div>)
        }
      </div>
    );
  }

  render() {
    const { selectedTimeline } = this.props;

    return(
      !!this.state.color.length && this.getStats(selectedTimeline)
    );
  }
}

export default WhiteBoxStats;
