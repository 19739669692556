import React, { Component } from 'react';
import UpIcon from './assets/ok-status-icon.jsx';
import DownIcon from './assets/red-check.jsx';
import Cone from './assets/cone-empty';
import MaintenanceIcon from './assets/maintenance.jsx';
import { Link, withRouter } from 'react-router-dom';
import NotOk from '../WhiteBoxHeader/assets/NotOk';
import './styles.scss';
import {
  formatText,
  formatDateAndTime,
  getTimeAgo
} from '../utils/index';

const getIcon = (indicator) => {
  if (indicator === 'up') {
    return UpIcon;
  }
  
  if (indicator === 'down' || indicator === 'outage') {
    return NotOk;
  }
  
  if (indicator === 'maintenance') {
    return MaintenanceIcon;
  }
  
  if (indicator === 'partialoutage') {
    return Cone;
  }
  
  if (indicator === 'incident') {
    return Cone;
  }
}

const isJSON = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

const getText = (text) => {
  if (isJSON(text)) {
    text = JSON.parse(text);
    if (!!text.en) return text.en;
    const [first] = Object.keys(text)
    return first;
  }
  return text;
}


class TopLevelStatus extends Component {
  isIncidentView() {
    const { pathname } = window.location;
    if (pathname.includes('/history/')) {
      return true;
    }
    return false;
  }

  render() {
    const { incident, statuspage, topLevelStatus } = this.props;
    const hasOngoingIncident = !!incident && !!incident.uuid;
    const titleStatus = !!incident ? incident.type : 'up';
    const title = hasOngoingIncident ? getText(incident.title) : getText(topLevelStatus.status);
    const TopLevelIcon = getIcon(hasOngoingIncident ? incident.type : topLevelStatus.indicator);

    return(
      <div className="whitebox top-level-status-wrapper">
        <div className="top-level-status">
          <div width="38px" height="38px" style={{ minWidth: '38px', width: '38px', height: '38px' }}><TopLevelIcon height="100%" width="100%" /></div>
          <div className="status">
            <span className={`title ${titleStatus}`}>{title}</span>
            {hasOngoingIncident && <span className="last-incident">
              <span dangerouslySetInnerHTML={{ __html: formatText(getText(incident.lastUpdateText)) }} />
              <div className="posted-at"> Last update: {formatDateAndTime(incident.lastUpdateDate)}</div>
            </span>}
            {!this.isIncidentView() && hasOngoingIncident && <span className="read-more">
              <Link
                to={{ pathname: '/history/' + incident.uuid, statuspage }}>
                Read more <span className="arrow-right">→</span>
              </Link>
            </span>}
            {!hasOngoingIncident && !!topLevelStatus.lastIncidentDate && <span className="read-more"><Link to={{ pathname: '/history', statuspage }}>
              Last incident {getTimeAgo(topLevelStatus.lastIncidentDate)} <span className="arrow-right">→</span>
            </Link></span>}
            {!hasOngoingIncident && !topLevelStatus.lastIncidentDate && <span className="read-more">No recent incident</span>}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(TopLevelStatus);
