import React, { Component } from 'react';
import Header from './Header';
import WhiteBoxHeader from './WhiteBoxHeader';
import WhiteBoxStats from './WhiteBoxStats';
import TimelineSelector from './TimelineSelector';
import Notification from './Notification';
import ProtectedPage from './ProtectedPage';
import './App.scss';
import { getBaseUrl, setFavicon } from './utils';
import { Link, withRouter } from 'react-router-dom';
import PoweredBy from './PoweredBy';
import API from './API';

const baseUrl = getBaseUrl();
  
class App extends Component {
  constructor(props) {
    super(props);

    let showNotification = false;
    let notificationMessage = '';
    if (!!this.props.location.search) {
      const { search } = this.props.location;
      showNotification = search.includes('confirm=true') || search.includes('unsubscribe=true');
      
      if (search.includes('confirm=true')) {
        notificationMessage = 'You successfully confirmed your subscription to this status page';
      } else if (search.includes('unsubscribe=true')) {
        notificationMessage = 'You successfully unsubscribed to this status page';
      }
    }

    this.state = {
      statusPageId: null,
      environment: '',
      loading: false,
      services: [],
      statuspage: {},
      timelineItems: [
        { value: 1, name: '24 hours'},
        { value: 8, name: '1 week'},
        { value: 31, name: '1 month'},
        { value: 91, name: '3 months'},
        { value: 365, name: '12 months'},
      ],
      selectedTimeline: 1,
      baseUrl,
      color: '',
      showNotification,
      notificationMessage,
      protected: false,
      protectedPageData: {},
      incidents: [],
      incident: {},
      topLevelStatus: {},
      hasLoaded: false,
      hasLoadedPings: false,
      hasLoadedSnapshots: false
    };
  }

  componentDidMount() {
    this.fetchStatuspage(this.props.history.location.response);
  }

  setTabName() {
    document.title = (this.state.statuspage.name || 'System') + ' Status';
  }

  fetchSnapshots() {
    if (this.state.protected) return;
    API.getSnapshots()
    .then((response) => {
      const services = this.state.services;

      response.forEach(service => {
        if (!!service && !!service.alias && service.snapshots && service.snapshots.length > 0) {
          const index = services.findIndex(s => s.alias === service.alias);
          if (index !== -1) {
            if (!!services[index]) {
              services[index].snapshots = service.snapshots;
            }
          } else {
            let groupIndex = -1;
            let serviceIndex = -1;
            services.forEach((s, i) => {
              if (s.isGroup) {
                const indexGroup = s.services.findIndex(sr => sr.alias === service.alias);
                if (indexGroup !== -1) {
                  groupIndex = indexGroup;
                  serviceIndex = i;
                }
              }
            });
            if (!!services[serviceIndex]) {
              services[serviceIndex].services[groupIndex].snapshots = service.snapshots;
            }
          }
        }
        if (!service.snapshots) {
          service.snapshots = [];
        }
      });
      this.setState({ services, hasLoadedSnapshots: true });
    });
  }

  fetchPings() {
    if (this.state.protected) return;
    API.getPings()
    .then((response) => {
      if (response.message === 'Unauthorized') {
        this.setState({ protected: true, protectedPageData: response.data });
        return Promise.reject();
      }
      const services = this.state.services;

      response.forEach(service => {
        if (service.pings.length > 0) {
          const index = this.state.services.findIndex(s => s.alias === service.alias);
          if (index !== -1) {
            if (!!services[index] && !!services[index].pings) {
              services[index].pings = service.pings;
            }
          } else {
            let groupIndex = -1;
            let serviceIndex = -1;
            services.forEach((s, i) => {
              if (s.isGroup) {
                const indexGroup = s.services.findIndex(sr => sr.alias === service.alias);
                if (indexGroup !== -1) {
                  groupIndex = indexGroup;
                  serviceIndex = i;
                }
              }
            });
            if (!!services[serviceIndex]) {
              services[serviceIndex].services[groupIndex].pings = service.pings;
            }
          }
        }
      });
      this.setState({ services, hasLoadedPings: true });
      this.fetchSnapshots();
    });
  }

  fetchStatuspage(response) {
    if (!!response) {
      setFavicon(response);
      this.setState({
        services: response.services,
        incident: response.ongoingIncident,
        statuspage: response.statuspage,
        topLevelStatus: response.topLevelStatus,
        hasLoaded: true
      });
      this.fetchPings();
    } else {
      this.setState({ loading: true });
      API.getStatuspage()
        .then((response) => {
          setFavicon(response);
          if (response.message === 'Unauthorized') {
            this.setState({ protected: true, protectedPageData: response.data });
            return Promise.reject();
          }
          return response;
        })
        .then((response) => {
          this.setState({
            services: response.services,
            incident: response.ongoingIncident,
            statuspage: response.statuspage,
            topLevelStatus: response.topLevelStatus,
            hasLoaded: true
          }, () => setFavicon(response.statuspage));
          this.fetchPings();
        });
    }
  }

  handleTimelineSelector = (value) => {
    const selectedTimeline = parseInt(value, 10);
    this.setState({ selectedTimeline });
  }

  hasSnapshotsLoaded() {
    const services = this.state.services;
    const servicesWithGraphs = services.filter(service => service.isResponseTimeSelected || service.isUptimeSelected);
    const hasSnapshots = servicesWithGraphs.every(service => !!service.snapshots);
    if (!hasSnapshots) {
      return false;
    }
    const hasLoaded = servicesWithGraphs.every(service => !!service.snapshots.length);
    return hasLoaded;
  }

  closeNotification = () => {
    this.setState({ showNotification: false });
  }

  hasAtLeastOneMetric = () => {
    let hasAtLeastOne = false;

    for (const service of this.state.services) {
      if (service.showUptime || service.showResponseTime) {
        hasAtLeastOne = true;
      }

      if (!!service.services && service.services.length) {
        for (const s of service.services) {
          if (s.showUptime || s.showResponseTime) {
            hasAtLeastOne = true;
          }
        }
      }
    }

    return hasAtLeastOne;
  }

  render() {
    if (this.state.protected) {
      return (
        <ProtectedPage
          data={this.state.protectedPageData}
        />
      )
    }

    const { incident, topLevelStatus, statuspage, hasLoaded } = this.state;

    return (
      <div>
        {this.state.showNotification && <Notification closeNotification={this.closeNotification} message={this.state.notificationMessage} type="success" />}
        <Header incident={incident} topLevelStatus={topLevelStatus} statuspage={statuspage} hasLoaded={hasLoaded} />

        {!!this.state.services.length &&
          <WhiteBoxHeader services={this.state.services} />
        }

        {this.state.hasLoadedPings && !!this.state.services.length && this.hasAtLeastOneMetric() &&
          <TimelineSelector
            items={this.state.timelineItems}
            onClick={event => this.handleTimelineSelector(event)}
            selected={this.state.selectedTimeline}
            hasSnapshots={this.hasSnapshotsLoaded()}
          />
        }

        {this.state.hasLoadedPings && !!this.state.services.length && !!statuspage &&
          <WhiteBoxStats
            services={this.state.services}
            selectedTimeline={this.state.selectedTimeline}
            color={statuspage.color} 
          />
        }

        {hasLoaded && <div className="incidents-container"><Link className="text-cta" style={{color: '#000'}} to={{
          pathname: "/history",
          statuspage,
          topLevelStatus,
          incident
        }}>See incident history <span className="arrow-right">→</span></Link></div>}

        {!!this.state.services.length &&
          <div className="right-wrapper">
            <PoweredBy />
          </div>
        }
      </div>
    );
  }
}

export default withRouter(App);
