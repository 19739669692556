import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      width={32}
      height={32}
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>{"Artboard"}</title>
      <path
        d="M18.108.63a1 1 0 00-.93-.63h-2.357a1 1 0 00-.93.63L10.96 8h10.082L18.108.63zM22.632 12H9.367L6.98 18H25.02l-2.387-6zM31 28h-2l-2.388-6H5.388L3 28H1a1 1 0 00-1 1v2a1 1 0 001 1h30a1 1 0 001-1v-2a1 1 0 00-1-1z"
        fill="#FFAF36"
        fillRule="nonzero"
      />
    </svg>
  )
}

export default SvgComponent
