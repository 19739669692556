import React, { Component } from 'react'; 
import './uptime.scss';
import { Area, defs, AreaChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { formatDateAndTime, getHourAndMinute} from '../utils';

const getTimeLabel = date => formatDateAndTime(date);

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
const getHumanDate = value => {
  const today = new Date(value)
  const year = today.getFullYear();
  const month = months[today.getMonth()];
  const day = today.getDate();
  return `${month} ${day > 9 ? '' + day: '0' + day}, ${year}`;
}

class Uptime extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: props.data,
      color: props.color
    };
  }

  CustomTooltip = (payload) => {
    let hour
    let name
    let date
    if (!!payload.payload.length) {
      name = payload.payload[0].name;
      hour = payload.payload[0].payload.hour;
      date = payload.payload[0].payload.ts;
    } else {
      return null;
    }

    const valueFormat = name === 'uptime' ? '%' : 'ms';
    const valueName = name === 'uptime' ? 'Uptime:' : 'Reponse time:';

    return (
      <div className="tooltip">
        <div className="header">{(this.props.labels === 'hours') ? getTimeLabel(date) : getHumanDate(hour)}</div>
        {!!payload.payload.length && <div className="value">{valueName} {payload.payload[0].value}{valueFormat}</div>}
      </div>
    )
  }

  render() {
    const { data, labels } = this.props;
    const dataset = data.map((row, index) => {
      if (data.length === index + 1) {
        if (labels === 'day' && row.skeleton === true) {
          row.uptime = data[index - 1].uptime;
          row.elapsedTime = data[index - 1].elapsedTime;
        }
      }
      if (labels === 'hours') {
        row.time = getHourAndMinute(row.date);
      }
      return row;
    });

    return(
      <ResponsiveContainer width="99%" height={80}>
        <AreaChart data={dataset} margin={{ bottom: -13, top: 5 }} >
          <XAxis
            dataKey={labels === 'hours' ? 'time' : 'day'}
            tick={{ fill: '#999', fontSize: 11 }}
            stroke="#efefef"
            // tickFormatter={name => `${name}${labels === 'hours' ? ':00' : ''}`}
          />
          <YAxis tickFormatter={label => {
            if (label >= 1000) {
              if (label % 1000 === 0) {
                return `${(label / 1000).toFixed(0)}s`
              } else {
                return `${(label / 1000).toFixed(1)}s`
              }
            }
            return label;
          }} width={30} tick={{ fill: '#999', fontSize: 11 }} stroke="#efefef" />
          <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="10%" stopColor={this.state.color} stopOpacity={0.3}/>
            <stop offset="100%" stopColor={this.state.color} stopOpacity={0}/>
          </linearGradient>
          </defs>
          <Area
            type="monotone"
            dataKey={this.props.dataKey}
            strokeWidth={2}
            stroke={this.state.color}
            dot={false}
            fillOpacity={1} fill="url(#colorUv)"
            isAnimationActive={false}
          />
          <Tooltip content={this.CustomTooltip} />
        </AreaChart>
      </ResponsiveContainer>
    );
  }
}

export default Uptime;
