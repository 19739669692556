import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      width={32}
      height={32}
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>{"Artboard"}</title>
      <g fill="none" fillRule="evenodd">
        <circle fill="#FFAF36" cx={16} cy={16} r={16} />
        <path
          d="M17.318 5.394A.625.625 0 0016.737 5h-1.474a.625.625 0 00-.58.394L12.85 10h6.3l-1.832-4.606zm2.827 7.106h-8.29l-1.493 3.75h11.275l-1.492-3.75zm5.23 10h-1.25l-1.493-3.75H9.368L7.875 22.5h-1.25a.625.625 0 00-.625.625v1.25c0 .345.28.625.625.625h18.75c.345 0 .625-.28.625-.625v-1.25a.625.625 0 00-.625-.625z"
          fill="#FFF"
          fillRule="nonzero"
        />
      </g>
    </svg>
  )
}

export default SvgComponent
