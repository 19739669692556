import React, { Component } from 'react';

class TimelineSelector extends Component {
  constructor(props) {
    super(props);

    const items = props.items;
    items.reverse();

    this.state = {
      items
    }
  }
  render() {
    const { selected, onClick, hasSnapshots }  = this.props;

    return(
      <div className="right-wrapper no-padding">
        {this.state.items.map(item => {
          if (!hasSnapshots && item.value > 1) {
            return null;
          }
          return(
            <span
              className={'selector-item ' + ((selected === item.value) ? 'selected' : '')}
              value={item.value}
              key={item.value}
              onClick={() => onClick(item.value)}
            >
              {item.name}
            </span>)
        }
        )}
      </div>
    )
  }
}

export default TimelineSelector;
