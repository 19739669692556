import * as React from "react"

function SvgComponent(props) {
  return (
    <svg viewBox="0 0 15 15" {...props}>
      <title>{"Artboard"}</title>
      <path
        d="M7.5 0C3.36 0 0 3.36 0 7.5 0 11.64 3.36 15 7.5 15c4.14 0 7.5-3.36 7.5-7.5C15 3.36 11.64 0 7.5 0zm4.44 5.94l-5 5a.62.62 0 01-.44.18.62.62 0 01-.44-.18l-2.5-2.5a.628.628 0 010-.88c.24-.24.64-.24.88 0L6.5 9.62l4.56-4.56c.24-.24.64-.24.88 0 .25.24.25.64 0 .88z"
        fill="#00C57D"
        fillRule="nonzero"
      />
    </svg>
  )
}

export default SvgComponent
