import React from 'react';
import ReactDOM from 'react-dom';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import './index.scss';
import App from './App';
import Incidents from './IncidentsPage';
import Incident from './IncidentPage';
import ConfirmSubscription from './ConfirmSubscriptionPage';
import Unsubscribe from './UnsubscribePage';
import { unregister } from './registerServiceWorker';

const routing = (
  <Router>
    <Switch>
      <Route exact path="/" component={App} />
      <Route exact path="/history" component={Incidents} />
      <Route exact path="/history/:incidentId" component={Incident} />
      <Route exact path="/confirm/:token" component={ConfirmSubscription} />
      <Route exact path="/unsubscribe/:token" component={Unsubscribe} />
    </Switch>
  </Router>
);

ReactDOM.render(routing, document.getElementById('root'));
unregister();
