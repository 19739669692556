import React, { Component } from 'react';
import Notification from '../Notification';
import mailSvg from './assets/mail.svg';
import phoneSvg from './assets/phone.svg';
import SlackSvg from './assets/slack';
import Select from 'react-select';
import './styles.scss';
import countries from './countries.json';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { getBaseUrl } from '../utils';

const baseUrl = getBaseUrl();

class Subscribe extends Component {
  constructor(props) {
    super(props);

    const { hostname } = window.location;

    this.state = {
      isOpened: false,
      countryCode: 'us',
      email: '',
      phone: '',
      showNotification: false,
      notificationType: '',
      notificationMessage: '',
      isLoading: false,
      hostname,
      selectedType: 'mail'
    };
  }

  componentWillMount() {
    document.addEventListener('mousedown', this.handleClick, false);
    document.addEventListener('keydown', this.escFunction, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false);
    document.removeEventListener('keydown', this.escFunction, false);
  }

  handleClick = (event) => {
    if (!!this.dropdownRef
      && !!this.buttonRef
      && (this.dropdownRef.contains(event.target) || this.buttonRef.contains(event.target))) {
      return;
    }

    this.setState({ isOpened: false });
  }

  escFunction = (event) => {
    if (event.keyCode === 27) {
      this.setState({ isOpened: false });
    }
  }

  isSelected = (type) => {
    if (type === this.state.selectedType) {
      return 'selected';
    }
  }

  selectType = (selectedType) => {
    this.setState({ selectedType });
  }

  submit = () => {
    if (this.state.isLoading) {
      return;
    }

    if (this.state.selectedType === 'mail' && !this.isEmailValid()) {
      return;
    }

    let phoneNumber = '';
    if (this.state.selectedType === 'phone') {
      if (this.state.countryCode.length !== 2) {
        return;
      }

      const formattedPhoneNumber = parsePhoneNumberFromString(this.state.phone, this.state.countryCode.toUpperCase());
      phoneNumber = formattedPhoneNumber.number;
    }

    this.setState({ isLoading: true });
    fetch(`${baseUrl}/v1/statuspage/subscribers`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: 'POST',
      body: JSON.stringify({
        email: this.state.email,
        phone: phoneNumber,
        type: this.state.selectedType,
        hostname: this.state.hostname
      })
    })
    .then((response) => response.json())
    .then((response) => {
      let notificationMessage = '';
      if (this.state.selectedType === 'mail') {
        notificationMessage = 'Your email is now subscribed to updates! An email has been sent to confirm the subscription';
      } else if (this.state.selectedType === 'phone') {
        notificationMessage = 'Your phone number is now subscribed to updates!';
      }

      if (response.message.includes('success')) {
        this.setState({
          showNotification: true,
          notificationMessage,
          notificationType: 'success',
          email: '',
          phone: '',
          countryCode: 'us',
          selectedType: 'mail',
          isOpened: false,
          isLoading: false
        });
      } else {
        this.setState({
          showNotification: true,
          notificationMessage: response.message,
          notificationType: 'danger',
          isLoading: false
        });
      }
    });
  }

  toggle = () => {
    this.setState({ isOpened: !this.state.isOpened });
  }

  isEmailValid = () => {
    const re = /^(?:[a-z0-9!#$%&amp;'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&amp;'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/
    return re.test(this.state.email);
  }

  isPhoneValid = () => {
    if (this.state.countryCode.length !== 2) {
      return false;
    }

    if (this.state.phone.length <= 6) {
      return false;
    }

    return true;
  }

  onChangeEmail = (event) => {
    this.setState({ email: event.target.value.toLowerCase() });
  }
  
  onChangePhone = (event) => {
    this.setState({ phone: event.target.value });
  }

  closeNotification = () => {
    this.setState({ showNotification: false });
  }

  isOriginalCoverColor = () => this.props.coverColor.toLowerCase().includes('fafbfc');

  getChannel = () => {
    if (this.isSelected('mail')) {
      return 'emails';
    } else if (this.isSelected('phone')) {
      return 'SMS';
    } else if (this.isSelected('slack')) {
      return 'Slack notifications';
    }
  }

  render() {
    const { isOpened } = this.state;
    const USAIndex = countries.findIndex(country => country.value === 'us');
    const { hasSubscriberPhoneFeature, hasSubscriberSlackFeature, pageCode } = this.props;

    return(
      <span>
      {this.state.showNotification &&
        <Notification
          closeNotification={this.closeNotification}
          message={this.state.notificationMessage}
          type={this.state.notificationType} 
        />
      }
      <div className="subscribe-wrapper">
        <span
          ref={buttonRef => this.buttonRef = buttonRef}
          onClick={this.toggle}
          className="subscribe"
          style={{
            color: this.isOriginalCoverColor() ? null : this.props.coverColor,
            backgroundColor: this.isOriginalCoverColor() ? null : "#FFF",
          }}
        >Subscribe to updates</span>
        {isOpened && <div className="subscriber" ref={dropdownRef => this.dropdownRef = dropdownRef}>
          {(hasSubscriberPhoneFeature || hasSubscriberSlackFeature) && <div className="alert-types">
            <div className={`alert-type ${this.isSelected('mail')}`} onClick={() => this.selectType('mail')}>
              <img src={mailSvg} alt="mail-icon" />
            </div>
            {hasSubscriberPhoneFeature && <div className={`alert-type ${this.isSelected('phone')}`} onClick={() => this.selectType('phone')}>
              <img src={phoneSvg} alt="phone-icon" />
            </div>}
            {hasSubscriberSlackFeature && <div className={`alert-type ${this.isSelected('slack')}`} onClick={() => this.selectType('slack')}>
              <SlackSvg width="20px" height="20px" />
            </div>}
          </div>}
          <span>Subscribe to <b>receive {this.getChannel()}</b> everytime {this.props.statuspageName} <b>publishes an update</b>.</span>
          {this.isSelected('mail') && <input type="text" className="input" placeholder="Email" name="email" value={this.state.email} onChange={this.onChangeEmail} autoFocus={true} />}
          {this.isSelected('phone') && <Select
            className="react-select-container"
            classNamePrefix="react-select"
            options={countries}
            defaultValue={countries[USAIndex]}
            autoFocus={true}
            onChange={(option) => this.setState({ countryCode: option.value })}
            styles={{
              marginTop: '5em'
            }}
          />}
          {this.isSelected('phone') && <input type="text" className="input" placeholder="2025550126" name="phone" value={this.state.phone} onChange={this.onChangePhone} />}
          {this.isSelected('mail') && <span className={`subscribe cta ${(!this.isEmailValid() || this.state.isLoading) ? 'disabled' : ''}`} onClick={this.submit}>Subscribe via email</span>}
          {this.isSelected('phone') && <span className={`subscribe cta ${(!this.isPhoneValid() || this.state.isLoading) ? 'disabled' : ''}`} onClick={this.submit}>Subscribe via SMS</span>}
          {this.isSelected('slack') && <a className="subscribe cta slack" target="_blank" href={`https://slack.com/oauth/v2/authorize?client_id=897172527474.1593345226673&scope=incoming-webhook,chat:write,channels:join&user_scope=&user_scope=&redirect_uri=https://subscriptions.hyperping.io/${pageCode}`}>Subscribe via Slack</a>}
        </div>}
      </div>
      </span>
    )
  }
}

export default Subscribe;
