import React, { Component } from 'react';

class PoweredBy extends Component {
  getHostname() {
    const { hostname } = window.location;
    return hostname;
  }

  render() {
    const hostname = this.getHostname();

    if (hostname === 'status.mysocket.io') return <><br/><br/></>;

    return (
      <div className="powered">
         <a
          target="_blank"
          rel="noopener noreferrer"
          href={`https://hyperping.io/status-page?utm_campaign=${hostname}&utm_medium=powered-by&utm_source=statuspage`}
        >Powered by <span className="by">Hyperping</span></a>
      </div>
    )
  }
}

export default PoweredBy;