import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      width={42}
      height={42}
      viewBox="0 0 42 42"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>{"Artboard"}</title>
      <g fill="none" fillRule="evenodd" stroke="#E95858" strokeWidth={3}>
        <path d="M21 40c5.971 0 11.3-2.755 14.782-7.062A18.92 18.92 0 0040 21c0-10.493-8.507-19-19-19S2 10.507 2 21s8.507 19 19 19z" />
        <g strokeLinecap="round" strokeLinejoin="round">
          <path d="M26 16L16 26M16 16l10 10" />
        </g>
      </g>
    </svg>
  )
}

export default SvgComponent
