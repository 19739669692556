import * as React from "react"

function SvgComponent(props) {
  return (
    <svg viewBox="0 0 15 15" {...props}>
      <title>{"Artboard"}</title>
      <g fillRule="nonzero" fill="none">
        <path
          d="M10 8.527l-.007.007A4.043 4.043 0 017.469 6l-5.963 5.964a1.79 1.79 0 002.53 2.53L10 8.527zM2.77 13.9a.67.67 0 110-1.342.67.67 0 010 1.342z"
          fill="#DADADA"
        />
        <path
          d="M13.824 7.839c-1.11 1.11-2.707 1.416-4.103.952l-.007.007a4.018 4.018 0 01-2.511-2.526l.003-.004c-.459-1.393-.155-2.991.948-4.094a4.01 4.01 0 013.803-1.055.333.333 0 01.158.56l-2.07 2.07.314 1.888 1.89.315 2.071-2.07a.333.333 0 01.56.154 4 4 0 01-1.056 3.803z"
          fill="#A9A9A9"
        />
      </g>
    </svg>
  )
}

export default SvgComponent
