import React, { Component } from 'react';
import Subscribe from '../Subscribe';
import TopLevelStatus from '../TopLevelStatus';
import './header.scss';
import { ReactComponent as ExternalLink } from './assets/external-link.svg';

class Header extends Component {
  constructor(props) {
    super(props);

    const baseUrl = !!process && !!process.env && process.env.REACT_APP_STAGE === 'dev'
      ? 'http://localhost:3200'
      : 'https://assets.hyperping.io';
    this.state = {
      baseUrl,
    }
  }

  hasSubscriberFeature = () => {
    const { statuspage } = this.props;

    return !!statuspage.features
      && !!statuspage.features.subscribers
      && statuspage.features.subscribers === true;
  }
  
  hasSubscriberPhoneFeature = () => {
    const { statuspage } = this.props;

    return !!statuspage.features
      && !!statuspage.features.subscribersPhone
      && statuspage.features.subscribersPhone === true;
  }
  
  hasSubscriberSlackFeature = () => {
    const { statuspage } = this.props;

    return !!statuspage.features
      && !!statuspage.features.subscribersSlack
      && statuspage.features.subscribersSlack === true;
  }

  isOriginalCoverColor = () => this.props.statuspage.coverColor.toLowerCase().includes('fafbfc');

  render() {
    const { statuspage, incident, topLevelStatus, hasLoaded } = this.props;
    const hasStatuspageLoaded = !!statuspage;

    return(
      <div className="header-wrapper" style={{ backgroundColor: hasStatuspageLoaded ? statuspage.coverColor : '#fff' }}>
        {hasStatuspageLoaded && <div className="header-content">
          <a href="/">
            {!!statuspage.image && !!statuspage.image.length &&
              <img className="image" src={`${this.state.baseUrl}/assets/images/${statuspage.image}`} alt="Logo" height="32px" />
            }
          </a>
          <span className="flex">
            {!!statuspage.homepage && <a
              className="go-to"
              href={statuspage.homepage}
              target="_blank"
              style={{ color: this.isOriginalCoverColor() ? '#000' : '#FFF' }}
            >
              <ExternalLink
                style={{
                  stroke: this.isOriginalCoverColor() ? '#000' : '#FFF',
                  width: '14px'
                }}
              /> Go to {statuspage.name}
            </a>}
            {this.hasSubscriberFeature() && <Subscribe
              coverColor={statuspage.coverColor}
              statuspageName={statuspage.name}
              pageCode={statuspage.pageCode}
              hasSubscriberPhoneFeature={this.hasSubscriberPhoneFeature()}
              hasSubscriberSlackFeature={this.hasSubscriberSlackFeature()}
            />}
          </span>
        </div>}
        {hasLoaded && <TopLevelStatus topLevelStatus={topLevelStatus} statuspage={statuspage} incident={incident} />}
      </div>
    )
  }
}

export default Header;
