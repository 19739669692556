import React from 'react';
import IncidentsComponent from './Incidents/index.jsx';
import ProtectedPage from './ProtectedPage';
import { withRouter } from 'react-router-dom';
import Header from './Header';
import PoweredBy from './PoweredBy';
import './App.scss';
import API from './API';
import { setFavicon } from './utils/index.js';

class Incidents extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      incident: props.history.location.incident,
      incidents: [],
      statuspage: props.history.location.statuspage,
      protected: false,
      protectedPageData: {},
      topLevelStatus: props.history.location.topLevelStatus,
      response: {},
      hasLoaded: !!props.history.location.incident
    }
  }

  componentDidMount() {
    this.fetchStatuspage();
  }

  fetchStatuspage = () => {
    API.getStatuspage()
      .then((response) => {
        if (response.message === 'Unauthorized') {
          this.setState({ protected: true, protectedPageData: response.data });
          return Promise.reject();
        }
        return response;
      })
      .then((response) => {
        this.setState({
          incident: response.ongoingIncident,
          statuspage: response.statuspage,
          topLevelStatus: response.topLevelStatus,
          hasLoaded: true,
          response
        }, () => setFavicon(response.statuspage));
      });

      API.getIncidents()
      .then((response) => {
        if (response.message === 'Unauthorized') {
          this.setState({ protected: true, protectedPageData: response.data });
          return Promise.reject();
        }
        return response;
      })
      .then((incidents) => {
        this.setState({ incidents });
      });
  }

  setTabName() {
    document.title = (this.state.statuspage.name || 'System') + ' Incidents';
  }

  hasFavicon() {
    return !!this.state.statuspage.favicon && this.state.statuspage.favicon.length
  }

  render() {
    if (this.state.protected) {
      return (
        <ProtectedPage
          data={this.state.protectedPageData}
        />
      )
    }

    const { statuspage, incident, incidents, topLevelStatus, hasLoaded, response } = this.state;
    if (!statuspage) {
      return null;
    }

    return(
      <div>
        <Header incident={incident} topLevelStatus={topLevelStatus} statuspage={statuspage} hasLoaded={hasLoaded} />

        {!!incidents && incidents.length > 0 && <IncidentsComponent statuspage={statuspage} incidents={incidents} response={response} />}

        <div className="right-wrapper"><PoweredBy /></div>
      </div>
    );
  }
}

export default withRouter(Incidents);
