import * as React from 'react';
import * as moment from 'moment-timezone';

export const formatDate = (rawDate) => {
  const date = new Date(rawDate);
  const formatted = moment(date).format('DD MMM YYYY');
  return formatted;
}

export const formatDateAndTime = (rawDate) => {
  const date = new Date(rawDate);
  const formatted = moment(date).format('D MMM YYYY H:mm A');
  return formatted;
}

export const getHourAndMinute = (rawDate) => {
  // 20:50
  const date = new Date(rawDate);
  const formatted = moment(date).format('HH:mm');
  return formatted;
}

export const getTimeAgo = rawDate => moment(new Date(rawDate), 'YYYYMMDD').fromNow();

export const formatText = text => !!text && text.split('\n').join('<br/>');

const areDatesOnSameMonth = (latestDateRaw, oldestDateRaw) => {
  const latestDate = new Date(latestDateRaw);
  const oldestDate = new Date(oldestDateRaw);

  const formattedLatestDate = moment(latestDate).format('MMM');
  const formattedOldestDate = moment(oldestDate).format('MMM');
  return formattedLatestDate === formattedOldestDate;
}

const areDatesOnSameDay = (latestDateRaw, oldestDateRaw) => {
  const latestDate = new Date(latestDateRaw);
  const oldestDate = new Date(oldestDateRaw);

  const latestDay = moment(latestDate).format('DD');
  const oldestDay = moment(oldestDate).format('DD');
  return { latestDay, oldestDay };
}

export const getDateRange = (incident) => {
  const { updates } = incident;

  const latest = updates[0];
  if (!latest) {
    return moment(incident.date).format('DD MMM YYYY');
  }
  const oldest = updates[updates.length - 1];
  const aresameMonth = areDatesOnSameMonth(latest.UTCTime, oldest.UTCTime);
  const { latestDay, oldestDay } = areDatesOnSameDay(latest.UTCTime, oldest.UTCTime);
  if (aresameMonth && (latestDay === oldestDay)) {
    return formatDate(latest.UTCTime);
  }

  if (aresameMonth && (latestDay !== oldestDay)) {
    return `${oldestDay} - ${latestDay} ${moment(latest.UTCTime).format('MMM YYYY')}`;
  }

  if (!aresameMonth) {
    return `${oldestDay} ${moment(new Date(oldest.UTCTime)).format('MMM')} - ${latestDay} ${moment(new Date(latest.UTCTime)).format('MMM YYYY')}`;
  }
}

export const isWithin24Hours = (dateRaw) => {
  const now = new Date();
  const hours = Math.abs(dateRaw - now) / 36e5;
  return hours <= 24;
}

export const isUpcoming = (dateRaw) => {
  const now = new Date();
  return moment(dateRaw).isAfter(now);
}

const getTimezone = () => moment.tz(moment.tz.guess()).format('z');

const getHourGrammar = (hour) => {
  if (hour > 1) {
    return ' hours';
  } else {
    return ' hour'
  }
}

const getMinuteGrammar = (minute) => {
  if (minute > 1) {
    return 'minutes';
  } else {
    return 'minute'
  }
}

export const getEstimatedDuration = (maintenance) => {
  const startDate = moment(maintenance.date);
  const startHumanDate = moment(maintenance.date).format('DD MMM h:mm A');
  const endDate = moment(maintenance.endDate);
  const endHumanDate = moment(maintenance.endDate).format('DD MMM h:mm A');

  const duration = moment.duration(endDate.diff(startDate));
  const hours = parseInt(duration.asHours());
  const minutes = parseInt(duration.asMinutes()) % 60;
  const timezone = getTimezone();

  const hasMinutes = minutes !== 0;
  const hasHours = hours !== 0;
  let humanDuration = `${hasHours ? (hours + getHourGrammar(hours)) : ''}`;
  if (hasHours && hasMinutes) {
    humanDuration += ` and ${minutes} ${getMinuteGrammar(minutes)}`
  } else if (hasMinutes) {
    humanDuration += `${minutes} ${getMinuteGrammar(minutes)}`
  }
  
  return (
    <>
      <span>Estimated duration: {humanDuration}</span><br/>
<span className="scheduled-time">{startHumanDate} {timezone} - {endHumanDate} {timezone}</span>
    </>
  );
}

export const getMaintenanceStatus = (startDate, endDate, index) => {
  if (index !== 0) {
    return;
  }

  const date = new Date();
  if (moment(date).isBetween(startDate, endDate)) {
    return 'In Progress';
  } else if (moment(date).isBefore(startDate)) {
    return 'Upcoming';
  } else if (moment(date).isAfter(endDate)) {
    return 'Completed'
  }
}

const { hostname, protocol } = window.location;

const getProtocol = () => {
  // Only *.hyperping.io domains can be accessible without https
  // status.* domains are being redirected to https
  if (hostname.includes('.hyperping.io') && protocol === 'http:') {
    return 'https://';
  }
  if (!!process && !!process.env && process.env.REACT_APP_STAGE === 'dev') {
    return 'http://';
  }
  return 'https://';
}

const devUrl = window.location.hostname;

export const getBaseUrl = () => !!process && !!process.env && process.env.REACT_APP_STAGE === 'dev'
  ? `http://${devUrl}:3011`
  : `${getProtocol()}api.hyperping.io`;

export const getHostname = () => process.env.REACT_APP_STAGE === 'dev' ? encodeURIComponent(`http://${devUrl}:3011`) : window.location.hostname;

const getIcon = (indicator) => {
  if (indicator === 'up') return '/static/media/check-ok.svg';
  if (indicator === 'down' || indicator === 'outage') return '/static/media/incident.svg';
  if (indicator === 'maintenance') return '/static/media/gear.svg';
  if (indicator === 'partialoutage') return '/static/media/incident.svg';
  if (indicator === 'incident') return '/static/media/incident.svg';
}

export const setFavicon = (statuspage) => {
  if (!statuspage) {
    return;
  }
  const { favicon } = statuspage;
  if (!!statuspage && !!statuspage.name) document.title = statuspage.name + ' - System status';
  
  if (!!favicon && favicon.length) {
    document.querySelector("link[rel='shortcut icon']").href = favicon;
  } else if (!!statuspage && !!statuspage.topLevelStatus) {
    document.querySelector("link[rel='shortcut icon']").href = getIcon(statuspage.topLevelStatus.indicator);
  }
}
