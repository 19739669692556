import React, { Component } from 'react';
import './styles.scss';
import Notification from '../Notification';
import { getBaseUrl } from '../utils';

const baseUrl = getBaseUrl();

class ProtectedPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      wrongPassword: false,
      image: '',
      showNotification: false,
      isLoading: false
    }
  }

  componentDidMount() {
    this.setFavicon();
    this.setTabName();
  }

  setFavicon() {
    const { favicon } = this.props.data;

    if (!!favicon) {
      document.querySelector("link[rel='shortcut icon']").href = favicon;
    }
  }

  setTabName() {
    document.title = (this.props.data.name || 'System') + ' Status';
  }

  onChangePassword = (e) => {
    this.setState({ password: e.target.value });
  }

  submit = (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });
    fetch(`${baseUrl}/v1/public/statuspage/authenticate`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      credentials: 'include',
      body: JSON.stringify({
        password: this.state.password,
        hostname: window.location.hostname
      })
    }).then(response => response.json())
      .then(response => {
        this.setState({ isLoading: false });
        if (response.message === 'Wrong password') {
          this.setState({ showNotification: true });
        } else {
          window.location.reload();
        }
      });
  }
  
  closeNotification = () => {
    this.setState({ showNotification: false });
  }

  render() {
    const { password, isLoading } = this.state;
    const { image, name } = this.props.data;

    return(
      <div className="protected-page">
        <form className="login-box" onSubmit={this.submit}>
          <div className="logo-wrapper">
            {!!image &&
              <div><img className="image" src={`https://assets.hyperping.io/assets/images/${image}`} alt="Logo" height="32px" /></div>
            }
            <div className="service-status">{name} Service Status</div>
          </div>
          <input
            type="password"
            className="input"
            placeholder="Password"
            name="password"
            value={password}
            onChange={this.onChangePassword}
            autoFocus={true}
          />
          <br/>
          <button type="submit" className={`btn-login ${isLoading ? 'disabled' : ''}`}>Submit</button>
        </form>
        {this.state.showNotification &&
          <Notification
            closeNotification={this.closeNotification}
            message="Wrong password"
            type="danger"
          />
        }
      </div>
    );
  }
}

export default ProtectedPage;
