import React from 'react';
import { Redirect } from 'react-router-dom';
import { getBaseUrl } from './utils';
import './App.scss';

const baseUrl = getBaseUrl();

class Unsubscribe extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      token: null,
      isUnsubscribed: false,
      redirect: false
    }
  }

  componentDidMount() {
    const { token } = this.props.match.params;

    this.setState({ token }, () => {
      this.confirmSusbcription();
    });
  }

  confirmSusbcription = () => {
    fetch(`${baseUrl}/v1/unsubscribe?token=${this.state.token}`)
      .then(response => response.json())
      .then((response) => {
        this.setState({ isUnsubscribed: true, redirect: true });
      });
  }

  renderRedirect = () => {
    const { pathname } = this.props.location;
    let pathnameDestination = '/';
    if (pathname.includes('/public/')) {
      pathnameDestination = pathname.split('/unsubscribe/')[0];
    }
    if (this.state.redirect) {
      return <Redirect to={{
        pathname: pathnameDestination,
        search: '?unsubscribe=true'
      }} />
    }
  }

  render() {
    return(
      <div className="container">
        {this.renderRedirect()}
        {!this.isUnsubscribed && <span>Unsubscribing</span>}
      </div>
    );
  }
}

export default Unsubscribe;
