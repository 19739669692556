import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      width={42}
      height={42}
      viewBox="0 0 42 42"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>{"Artboard"}</title>
      <g fill="none" fillRule="evenodd">
        <path
          d="M21 40c5.971 0 11.3-2.755 14.782-7.062A18.92 18.92 0 0040 21c0-10.493-8.507-19-19-19S2 10.507 2 21s8.507 19 19 19z"
          stroke="#FFAF36"
          strokeWidth={3}
        />
        <path
          d="M22.255 9.473c-.09-.286-.31-.473-.553-.473h-1.404c-.243 0-.462.187-.553.473L18 15h6l-1.745-5.527zM25.412 17h-8.824L15 22h12l-1.588-5zm5.9 11.6h-1.375L28.297 25H13.704l-1.642 3.6h-1.374c-.38 0-.688.269-.688.6v1.2c0 .331.308.6.688.6h20.624c.38 0 .688-.269.688-.6v-1.2c0-.331-.308-.6-.688-.6z"
          fill="#FFAF36"
          fillRule="nonzero"
        />
      </g>
    </svg>
  )
}

export default SvgComponent
