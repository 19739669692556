import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      width={32}
      height={32}
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>{"Artboard"}</title>
      <g fill="none" fillRule="evenodd">
        <circle fill="#E95858" fillRule="nonzero" cx={16} cy={16} r={16} />
        <g
          stroke="#FFF"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={3}
        >
          <path d="M22 10L10 22M10 10l12 12" />
        </g>
      </g>
    </svg>
  )
}

export default SvgComponent
