import React from 'react';
import IncidentsComponent from './Incidents/index.jsx';
import Header from './Header';
import './App.scss';
import ProtectedPage from './ProtectedPage';
import { setFavicon } from './utils';
import { withRouter } from 'react-router-dom';
import PoweredBy from './PoweredBy';
import API from './API';


class Incident extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      incident: props.history.location.incident,
      statuspage: props.history.location.statuspage,
      incidentId: props.match.params.incidentId,
      protected: false,
      protectedPageData: {}
    }
  }

  componentDidMount() {
    this.fetchStatuspage();
    this.fetchIncident();
  }

  fetchStatuspage = () => {
    if (!!this.state.statuspage) {
      this.setTabName();
      return null;
    }

    API.getStatuspage()
      .then((response) => {
        if (response.message === 'Unauthorized') {
          this.setState({ protected: true, protectedPageData: response.data });
          return Promise.reject();
        }
        return response;
      })
      .then((response) => {
        this.setState({
          statuspage: response.statuspage,
          response
        }, () => setFavicon(response.statuspage));
      });
  }


  fetchIncident() {
    if (!!this.state.incident) {
      return;
    }

    API.getIncident(this.state.incidentId)
    .then((response) => {
      if (response.message === 'Unauthorized') {
        this.setState({ protected: true, protectedPageData: response.data });
        return Promise.reject();
      }
      return response;
    })
    .then((response) => {
      this.setState({ incident: response.incident, });
    });
  }

  setTabName() {
    document.title = this.state.statuspage.name + ' Incidents';
  }

  hasFavicon() {
    return !!this.state.statuspage.favicon && this.state.statuspage.favicon.length;
  }

  render() {
    if (this.state.protected) {
      return (<ProtectedPage data={this.state.protectedPageData} />)
    }

    const { incident, statuspage } = this.state;

    if (!statuspage) {
      return null;
    }

    return (
      <div>
        <Header statuspage={statuspage} />

        {!!incident
          && <IncidentsComponent statuspage={statuspage} isHomePage={false} isIncidentPage={true} incidents={[incident]} />
        }
        <div className="right-wrapper">
          <PoweredBy />
        </div>
      </div>
    );
  }
}

export default withRouter(Incident);
