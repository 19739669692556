import React, { Component } from 'react';
import Wrench from '../TopLevelStatus/assets/wrench';
import up from './assets/ok-status-icon.svg';
import down from './assets/red-check.svg';
import NotOk from './assets/NotOk';
import Cone from '../TopLevelStatus/assets/cone-full';
import Plus from './assets/plus';
import Minus from './assets/minus';

const getAlias = (service) => {
  return (!!service.alias && !!service.alias.length) ? service.alias : service.domain;
}

const GetRow = ({ service, onClick, isExpanded }) => (
  <>
    <div className="left" onClick={onClick}>
      <span style={{display: 'flex'}}>{service.isGroup && <span className="group-collapsible">
        {isExpanded && <Minus width="15px" height="15px" />}
        {!isExpanded && <Plus width="15px" height="15px" />}
      </span>}
      {getAlias(service)}</span>
      {(service.status === 'up' || service.status === 'available') && !isExpanded && <span className="status-type operational">Operational</span>}
      {(service.status === 'incident' || service.status === 'partialoutage') && <span className="status-type service-issue">Service issue</span>}
      {(service.status === 'outage' || service.status === 'down') && <span className="status-type service-outage">Service outage</span>}
      {service.status === 'maintenance' && <span className="status-type maintenance">Under maintenance</span>}
    </div>
    {!isExpanded && <div className="right">
      {(service.status === 'up' || service.status === 'available') && <img src={up} alt="Service is up" width="24px"/>}
      {(service.status === 'incident' || service.status === 'partialoutage') && <Cone height="24px" width="24px" />}
      {(service.status === 'outage' || service.status === 'down') && <NotOk alt="Service outage" width="24px"/>}
      {service.status === 'maintenance' && <Wrench height="22px" width="22px" />}
    </div>}
  </>
);

const getIndex = (services, index) => {
  if (index === 0 && services.length > 1) {
    return 'first';
  } else if ((index + 1) === services.length) {
    return 'last';
  } else {
    return '';
  }
}

const GetServices = ({ services, onClick, collapsed }) => (
  services.map((service, index) => (
    <div className={'status-row ' + getIndex(services, index)} key={index}>
      <div className="status-row-header"><GetRow service={service} onClick={() => service.isGroup && onClick(index)} isExpanded={collapsed.includes(index)} /></div>
      {collapsed.includes(index) && service.isGroup && service.services.map(s => <div key={s.alias} className="sub-status"><GetRow service={s} /></div>)}
    </div>
  ))
)

const WhiteBoxHeader = ({ services }) => {
  const [collapsed, setCollapsed] = React.useState([]);

  const toggleGroup = (index) => {
    const indexRemove = collapsed.findIndex(c => c === index);

    if (indexRemove === -1) {
      setCollapsed([...collapsed, index]);
    } else {
      const newCollapsed = collapsed;
      newCollapsed.splice(indexRemove, 1);
      setCollapsed([...newCollapsed]);
    }
  }

  return(<div className="whitebox no-padding"><GetServices services={services} collapsed={collapsed} onClick={toggleGroup} /></div>);
}

export default WhiteBoxHeader;
