import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      width={32}
      height={32}
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>{"Artboard"}</title>
      <g fill="#A5ADB4" fillRule="nonzero">
        <path d="M20.125 17.53l-.017.016a9.039 9.039 0 01-5.643-5.665L1.13 25.213a4 4 0 005.656 5.656l13.338-13.338v-.001z" />
        <path d="M29.355 15.388c-2.5 2.5-6.092 3.187-9.232 2.142l-.017.016a9.04 9.04 0 01-5.65-5.684l.008-.008c-1.034-3.135-.35-6.732 2.133-9.212A9.024 9.024 0 0125.154.267a.75.75 0 01.354 1.262L20.85 6.184l.708 4.25 4.254.709 4.658-4.658a.75.75 0 011.26.345 9 9 0 01-2.375 8.558z" />
      </g>
    </svg>
  )
}

export default SvgComponent
