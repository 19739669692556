import { getBaseUrl } from '../utils';

const fetchWrapper = (endpoint, method = 'GET', params) => {
  const urlParams = { hostname: window.location.hostname, ...params };
  return fetch(getBaseUrl() + endpoint + '?' + new URLSearchParams(urlParams), {
    method,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'include'
  })
  .then((response) => response.json());
}

const getIncidents  = () =>     fetchWrapper('/v2/statuspages/incidents');
const getIncident   = (uuid) => fetchWrapper('/v2/statuspages/incident', 'GET', { uuid });
const getSnapshots  = () =>     fetchWrapper('/v2/services-snapshots');
const getPings      = () =>     fetchWrapper('/v2/services-pings');
const getStatuspage = () =>     fetchWrapper('/v2/services');

export default {
  getIncidents,
  getIncident,
  getSnapshots,
  getPings,
  getStatuspage
}
