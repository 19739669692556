import React from 'react';

import './styles.scss';

class Notification extends React.Component {
  componentDidMount() {
    setTimeout(() => {
      this.props.closeNotification();
    }, 4500);
  }

  render() {
    return(
      <div className={`notification ${this.props.type}`}>{this.props.message}</div>
    );
  }
}

export default Notification;
